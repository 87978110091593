import React from "react";
import Img from "gatsby-image";
import {
  StandalonePageLayoutDiv,
  HeroImage,
  ImagePageLayoutDiv,
  ImagePageLayoutWrapperDiv,
  WhiteBoxDiv,
} from "./elements";

const StandalonePageLayout = ({ children }) => (
  <StandalonePageLayoutDiv><div className="std-content">{children}</div></StandalonePageLayoutDiv>
);

export const ImagePageLayout = ({
  children,
  gatsbyImageImage,
  overlay,
  fixedImage,
  topAdjustment,
}) => (
  <ImagePageLayoutWrapperDiv overlay={overlay} fixedImage={fixedImage} style={{marginTop:'50px'}}>
    {gatsbyImageImage ? (
      <Img fixed={gatsbyImageImage} />
    ) : (
      <HeroImage gatsbyImageImage={gatsbyImageImage} />
    )}
    <ImagePageLayoutDiv topAdjustment={topAdjustment}>
      {children}
    </ImagePageLayoutDiv>
  </ImagePageLayoutWrapperDiv>
);

export const WhiteBox = ({ children }) => <WhiteBoxDiv>{children}</WhiteBoxDiv>;

export default StandalonePageLayout;
