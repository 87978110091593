import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "./../components/Layout";
import { ImagePageLayout } from "./../components/StandalonePageLayout";
import { SmallButton } from "./../components/Buttons";
import RegisterYourInterestForm from "./../components/RegisterYourInterestForm";

const CenteredButtonP = styled.p`
  margin-top: 50px !important;
  text-align: center;
`;

const JoinOurDistrictPage = () => {
  const joinQuery = useStaticQuery(graphql`
    {
      craftJoinOurDistrictJoinOurDistrictEntry {
        richTextField
        title
        callToAction
        headerImage {
          ... on Craft_editorialAssets_Asset {
            id
            wideImage: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  `).craftJoinOurDistrictJoinOurDistrictEntry;
  const { title, richTextField, callToAction } = joinQuery;
  const [formShown, setFormShown] = React.useState(false);
  const gatsbyImageImage =
    joinQuery.headerImage[0].wideImage.childImageSharp.fixed;
  return (
    <Layout
      backgroundColor={"var(--red)"}
      footerColor={"var(--red)"}
      headerColor={"var(--white)"}
      title={title}
    >
      <ImagePageLayout gatsbyImageImage={gatsbyImageImage}>
        <h1>{title}</h1>
        <div className="joinourdistrict">
          <div>
            <div dangerouslySetInnerHTML={{ __html: richTextField }} />
            <CenteredButtonP>
              <SmallButton
                onClick={() => {
                  setFormShown(true);
                }}
              >
                {callToAction || "GET IN TOUCH"}
              </SmallButton>
            </CenteredButtonP>
          </div>
        </div>
      </ImagePageLayout>
      {formShown ? (
        <RegisterYourInterestForm
          closeForm={() => {
            setFormShown(false);
          }}
          isJoin
        />
      ) : null}
    </Layout>
  );
};

export default JoinOurDistrictPage;
