import styled from "styled-components";

export const StandalonePageLayoutDiv = styled.div`
  width: 610px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 50px;
  z-index: 1;
  & h1 {
    font-weight: 600;
    font-size: 110px;
    position: absolute;
    z-index: 1;
    margin: 0;
    top: -3px;
    left: -300px;
    white-space: nowrap;
    & + div {
      margin-top: 40px;
      z-index: 2;
      position: relative;
    }
  }
  & p,
  li {
    font-size: 18px;
  }
  @media (max-width: 1439px) {
    & > h1 {
      position: fixed;
      top: 53px;
      left: 25px;
      z-index: 0;
    }
    & > div {
      z-index: 3;
    }
  }
  @media (max-width: 767px) {
    width: calc(100% - 50px);
    margin-left: 25px;
    margin-right: 25px;
    padding-top: 0;
    & > h1 {
      font-size: 36px;
      top: 0;
      left: 0;
      position: relative;
      & + div {
        margin-top: 20px;
      }
      & ~ div > div {
        padding-left: 10px;
        padding-right: 10px;
        &.map {
          padding-left: 0;
          padding-right: 0;
        }
        & + div + div {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
`;

export const ImagePageLayoutDiv = styled.div`
  width: 810px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 0;
  margin-bottom: 50px;
  min-height: 800px; /* added for very small pages */
  padding-top: 10px;
  margin-top: ${(props) => (props.topAdjustment ? props.topAdjustment : 0)};
  & h1 {
    transition: 0.5s;
    font-weight: 600;
    font-size: 110px;
    position: absolute;
    z-index: 1;
    margin: 0;
    top: -53px;
    left: -275px;
    white-space: nowrap;
    color: var(--white);
    & + div {
      transition: 0.5s;
      margin-top: 40px;
      z-index: 2;
      position: relative;
    }
  }
  & blockquote {
    font-size: 24px;
    line-height: 31.2px;
    letter-spacing: 0.24px;
    font-weight: 700;
    margin: 0 0 31.2px 0;
  }
  & h2 {
    font-style: italic;
    color: var(--red);
    font-size: 18px;
    line-height: 23.4px;
    letter-spacing: 0.36px;
    font-weight: 600;
    margin: 23.4px 0 0 0;
  }
  & h3 {
    margin: 23.4px 0 0 0;
    font-size: 18px;
    line-height: 25.2px;
    font-weight: 700;
    letter-spacing: 0.36px;
  }
  & p,
  li {
    font-size: 18px;
    margin: 0;
  }
  & > div {
    background-color: rgba(255, 255, 255, 0.85);
    display: flex;
    margin-bottom: 5px;
    padding: 50px var(--outerMargin);
  }
  @media (max-width: 1439px) {
    & h1 {
      position: relative;
      left: 0;
      top: -30px;
      & + div {
        margin-top: 0;
      }
    }
  }
  @media (max-width: 1023px) {
    width: 100%;
    /* min-height: initial; */
    margin-bottom: 0;
    --outerMargin: 50px;
    & h1 {
      padding: 0 50px;
      font-size: 80px;
      & + div {
      }
    }
  }
  @media (max-width: 767px) {
    & h1 {
      font-size: 48px;
      padding: 50px 50px 0 50px;
      max-width: 100%;
      white-space: initial;
      line-height: 48px;
    }
    & .joinourdistrict {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
`;

export const HeroImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 704px;
  width: 100%;
`;

export const WhiteBoxDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  display: flex;
  margin-bottom: 5px;
  & > div {
    max-width: 400px;
    margin: 40px auto 50px auto;
  }
  & iframe{
    width:100%
  }
  & img{
    width:100%;
  }
  @media (max-width: 767px) {
    & > div {
      margin-top: 10px;
      margin-bottom: 10px;
      & > p {
        margin: 0;
        & + p {
          margin-top: 18px;
        }
      }
    }
  }
`;

export const ImagePageLayoutWrapperDiv = styled.div`
  & > .gatsby-image-wrapper {
    position: ${(props) =>
      props.fixedImage ? "fixed" : "absolute"} !important;
    top: 0;
    left: 0;
    right: 0;
    height: 704px !important;
    width: 100% !important;
    min-height: 100vh;
    z-index: 1;
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: "";
      background-color: ${(props) =>
        props.overlay ? props.overlay : "transparent"};
      mix-blend-mode: multiply;
    }
  }
  @media (max-width: 767px) {
    & > .gatsby-image-wrapper {
      min-height: 100%;
    }
  }
`;
